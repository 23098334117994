<template>
  <div class="page">
    <el-form size="small"
             :inline="true"
             class="query-form"
             ref="searchForm"
             :model="searchForm"
             @keyup.enter.native="refreshList()"
             @submit.native.prevent>
      <el-form-item prop="type">
        <el-input size="small"
                  v-model="searchForm.description"
                  placeholder="描述" maxlength="66"
                  clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="pageNo=1;refreshList()"
                   size="small"
                   icon="el-icon-search">查询</el-button>
        <el-button @click="pageNo=1;resetSearch()"
                   size="small"
                   icon="el-icon-refresh-right">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="top bg-white">
      <el-row>
        <el-button type="primary"
                   size="small"
                   icon="el-icon-plus"
                   @click="add()">新建
        </el-button>
        <el-button type="warning"
                   size="small"
                   icon="el-icon-edit-outline"
                   @click="edit()"
                   :disabled="dataListSelections.length !== 1"
                   plain>修改
        </el-button>
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   @click="del()"
                   :disabled="dataListSelections.length <= 0"
                   plain>删除
        </el-button>
        <el-button-group class="f_r">
          <el-tooltip class="item"
                      effect="dark"
                      content="刷新"
                      placement="top">
            <el-button type="default"
                       size="small"
                       icon="el-icon-refresh"
                       @click="refreshList">
            </el-button>
          </el-tooltip>
        </el-button-group>
      </el-row>
      <el-table :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100% - 80px)"
                @selection-change="selectionChangeHandle"
                class="table">
        <el-table-column type="selection"
                         header-align="center"
                         align="center"
                         width="50">
        </el-table-column>
        <el-table-column prop="type"
                         label="类型">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="view(scope.row.id)">{{scope.row.type}}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column prop="description"
                         label="描述">
        </el-table-column>
        <el-table-column fixed="right"
                         :key="Math.random()"
                         header-align="center"
                         align="center"
                         width="300"
                         label="操作">
          <template slot-scope="scope">
            <el-button type="text"
                       size="small"
                       @click="view(scope.row.id)">
              查看
            </el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text"
                       size="small"
                       @click="edit(scope.row.id)">
              修改
            </el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text"
                       size="small"
                       @click="del(scope.row.id)">
              删除
            </el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text"
                       size="small"
                       @click="showRight(scope.row)">
              管理键值
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle"
                     @current-change="currentChangeHandle"
                     :current-page="pageNo"
                     :page-sizes="[10, 20, 50, 100]"
                     :page-size="pageSize"
                     :total="total"
                     background
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <dict-type-form ref="dictTypeForm"
                    @refreshDataList="refreshList"></dict-type-form>
    <el-drawer size="700px"
               :title="`数据字典值列表，所属类型: ${this.dictTypeTitle}`"
               :visible.sync="rightVisible"
               direction="rtl">
      <dict-value-list :dict-type-title="dictTypeTitle"
                       ref="dictValueList"
                       @closeRight="closeRight"></dict-value-list>
    </el-drawer>

  </div>
</template>

<script>
import DictTypeForm from './DictTypeForm'
import DictValueList from './DictValueList'

import {
  declarationFormTemplateDictTypesApi,
  declarationFormTemplateDictTypeDeleteApi,
} from '@/api/declaration/declarationFormTemplateDict'

export default {
  data() {
    return {
      searchForm: {
        description: '',
      },
      dataList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      dataListSelections: [],
      dictTypeTitle: '',
      rightVisible: false,
      loading: false,
    }
  },
  components: {
    DictTypeForm,
    DictValueList,
  },
  activated() {
    this.refreshList()
  },
  methods: {
    // 获取数据列表
    refreshList() {
      this.loading = true
      declarationFormTemplateDictTypesApi(
        this.pageNo,
        this.pageSize,
        this.searchForm.description
      ).then((data) => {
        if (data && data.status) {
          this.dataList = data.data.records
          this.total = parseInt(data.data.total)
          this.loading = false
        }
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增
    add() {
      this.$refs.dictTypeForm.init('add', '')
    },
    // 修改
    edit(id) {
      id =
        id ||
        this.dataListSelections.map((item) => {
          return item.id
        })[0]
      this.$refs.dictTypeForm.init('edit', id)
    },
    // 查看
    view(id) {
      this.$refs.dictTypeForm.init('view', id)
    },
    // 删除
    del(id) {
      let ids =
        id ||
        this.dataListSelections
          .map((item) => {
            return item.id
          })
          .join(',')
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        declarationFormTemplateDictTypeDeleteApi(ids).then((res) => {
          if (res && res.status) {
            this.$message.success(res.msg)
            if(ids.split(",").length==this.dataList.length){
              this.pageNo=1
            }
            console.log(this.pageNo)
            this.refreshList()
            this.$dictUtils.refreshDictList()
          }
        })
      })
    },
    resetSearch() {
      this.searchForm.description = ''
      this.refreshList()
    },
    showRight(row) {
      this.rightVisible = true
      this.$nextTick(() => {
        this.$refs.dictValueList.refreshList(row.id)
        this.dictTypeTitle = row.type
      })
    },
    closeRight() {
      this.rightVisible = false
    },
  },
}
</script>
